<template>
  <v-dialog
    max-width="1000px"
    width="100%"
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
  >
    <v-card :loading="loading">
      <v-toolbar color="info" dark flat>
        <v-toolbar-items>
          <PersonItem :value="overview.person" />
        </v-toolbar-items>

        <v-spacer />
        <v-icon icon @click="dialog = false">mdi-close</v-icon>

        <template v-slot:extension>
          <v-tabs
            v-model="type"
            light
            background-color="white"
            color="info"
            centered
            ><v-tab>Beitrag Kanton</v-tab><v-tab>Eigene Kosten</v-tab></v-tabs
          >
        </template>
      </v-toolbar>
      <v-card-text class="ma-0 text-right">
        <template v-if="type === 0">
          <span class="mr-2">Beitrag Kanton</span>
          <v-chip light
            ><CurrencyValue :value="overview.balanceCantonal" />
          </v-chip>
        </template>
        <template v-else>
          <span class="mr-2">Eigene Kosten</span>
          <v-chip light>
            <CurrencyValue light :value="overview.balanceOwn" />
          </v-chip>
        </template>
      </v-card-text>
      <v-card-text class="ma-0 pa-0">
        <v-data-table
          v-if="!$vuetify.breakpoint.xs"
          :headers="headers"
          :items="items"
          disable-sort
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.text="{ item }">
            <strong>{{ item.event }}</strong>
            <br />{{ item.description }}
          </template>
          <template v-slot:item.date="{ item }">
            <DateValue :value="item.date" />
          </template>
          <template v-slot:item.amount="{ item }">
            <CurrencyValue :value="item.amount" />
          </template>
          <template v-slot:item.balance="{ item }">
            <CurrencyValue :value="item.balance" />
          </template>
          <template v-slot:item.creator="{ item }">
            <PersonItem small :value="item.creator" />
          </template>
        </v-data-table>

        <v-list v-else>
          <v-list-item
            v-for="item in items"
            :key="item.id"
            :three-line="!!item.number"
          >
            <v-list-item-avatar
              ><PortraitImage :value="item.creator"
            /></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                <DateValue :value="item.date" />
              </v-list-item-subtitle>
              <v-list-item-title
                ><strong>{{ item.event ? item.event : "" }}</strong>
                {{ item.description }}</v-list-item-title
              >

              {{ item.number ? item.number : "" }}
            </v-list-item-content>
            <v-chip><CurrencyValue :value="item.amount" /></v-chip>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer /><v-btn text @click="dialog = false"
          >schliessen</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyValue from "@/components/CurrencyValue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import PortraitImage from "common/components/PortraitImage.vue";

export default defineComponent({
  components: { DateValue, CurrencyValue, PersonItem, PortraitImage },
  props: ["id"],
  data: () => ({
    overview: { student: {}, balance: 0 },
    items: [],
    loading: false,
    dialog: true,
    type: 1, // tab index: 0 === cantonal, 1 === own
    headers: [
      { text: "Datum", value: "date" },
      { text: "Beschreibung", value: "text" },
      { text: "Person", value: "creator" },
      { text: "Rechnungsnummer", value: "invoiceNumber" },
      { text: "Betrag", value: "amount", align: "end" },
    ],
  }),
  watch: {
    type() {
      this.fetchData();
    },
    dialog() {
      if (!this.dialog) {
        this.$router.push({
          name: "FinanceClass",
        });
      }
    },
    id() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.overview = await this.apiGet({
        resource: "finance/personoverview",
        id: this.id,
      });
      this.items = await this.apiList({
        resource: "finance/persontransaction",
        query: `person=${this.id}&type=${this.type === 0 ? "cantonal" : "own"}`,
      });
      this.loading = false;
    },
  },

  async created() {
    this.fetchData();
  },
});
</script>
